const footprintClassNamesMap = {
  button: '.fp-button',
  input: '.fp-input',
  label: '.fp-label',
  hint: '.fp-hint',
  linkButton: '.fp-link-button',
  link: 'a',
};

export default footprintClassNamesMap;
