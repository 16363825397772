import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoShield40 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.513 6.18a1.25 1.25 0 0 0-1.023 0L6.81 11.862a1.25 1.25 0 0 0-.736 1.057l1.248.084a133.712 133.712 0 0 0-1.248-.082v.02l-.004.05-.008.179c-.007.154-.015.377-.02.658a34.5 34.5 0 0 0 .045 2.313c.099 1.902.381 4.463 1.127 7.045.743 2.573 1.971 5.252 4.02 7.3 2.08 2.08 4.94 3.44 8.766 3.44 3.827 0 6.687-1.36 8.767-3.44 2.048-2.048 3.276-4.727 4.02-7.3.746-2.582 1.028-5.143 1.127-7.045.049-.954.053-1.751.044-2.313a24.877 24.877 0 0 0-.028-.838l-.003-.05V12.922l-1.248.083 1.247-.084a1.25 1.25 0 0 0-.736-1.057L20.513 6.179Zm-11.97 7.689.001-.043L20.001 8.69l11.457 5.136.001.043a31.94 31.94 0 0 1-.041 2.144c-.093 1.787-.357 4.144-1.032 6.48-.678 2.346-1.744 4.585-3.386 6.226-1.608 1.609-3.83 2.708-6.999 2.708-3.168 0-5.39-1.1-6.999-2.708-1.641-1.64-2.708-3.88-3.385-6.226-.675-2.336-.94-4.693-1.032-6.48-.046-.89-.05-1.63-.041-2.144Zm16.406 2.927a1.25 1.25 0 1 0-2.026-1.464l-4.741 6.564-1.155-1.386a1.25 1.25 0 0 0-1.92 1.6l2.185 2.624a1.25 1.25 0 0 0 1.974-.069l5.683-7.87Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoShield40;
