import React from 'react';
import { useTheme } from 'styled-components';

import type { IconProps } from '../types';

const IcoChartUp40 = ({ color = 'primary', className, testID }: IconProps) => {
  const theme = useTheme();
  return (
    <svg
      width={40}
      height={40}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid={testID}
      className={className}
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.453 9.23a.494.494 0 0 0-.494.494v20.552c0 .272.221.494.494.494h.822a.494.494 0 0 0 .494-.494V9.724a.494.494 0 0 0-.494-.494h-.822Zm-2.794.494a2.794 2.794 0 0 1 2.794-2.794h.822a2.794 2.794 0 0 1 2.794 2.794v20.552a2.794 2.794 0 0 1-2.794 2.793h-.822a2.794 2.794 0 0 1-2.794-2.794V9.725ZM15.99 9.23l-8.723 8.723a1.15 1.15 0 1 0 1.626 1.627l8.723-8.724v2.978a1.15 1.15 0 0 0 2.3 0V8.08a1.15 1.15 0 0 0-1.15-1.15h-5.754a1.15 1.15 0 0 0 0 2.3h2.978ZM9.724 25.67a.494.494 0 0 0-.494.495v4.11c0 .273.221.495.494.495h.822a.494.494 0 0 0 .494-.494v-4.111a.494.494 0 0 0-.494-.494h-.822Zm-2.794.495a2.794 2.794 0 0 1 2.794-2.794h.822a2.794 2.794 0 0 1 2.794 2.794v4.11a2.794 2.794 0 0 1-2.794 2.794h-.822a2.794 2.794 0 0 1-2.794-2.794v-4.11Zm12.165-4.932c0-.273.22-.495.494-.495h.822c.272 0 .494.222.494.495v9.043a.494.494 0 0 1-.494.494h-.822a.494.494 0 0 1-.494-.494v-9.043Zm.494-2.794a2.794 2.794 0 0 0-2.794 2.794v9.043a2.794 2.794 0 0 0 2.794 2.793h.822a2.794 2.794 0 0 0 2.794-2.794v-9.042a2.794 2.794 0 0 0-2.794-2.794h-.822Z"
        fill={theme.color[color]}
      />
    </svg>
  );
};
export default IcoChartUp40;
